import { IRoutes } from '@/types/Routes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFound from '../views/404.vue';
import LoginView from '../views/LoginView.vue';
import MainView from '../views/MainView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/products/all/1',
    component: MainView,
    meta: {
      title: 'Main page | COSMIOS',
      isAuthRequired: true,
    },
    children: [
      /* Brand */
      {
        path: '/brands/all/:pageId?',
        name: 'Brands',
        component: () => import('../views/Brands/AllBrandsView.vue'),
        meta: {
          title: 'All Brands',
        },
      },
      {
        path: '/brands/new/:pageId?',
        name: 'New brands',
        component: () => import('../views/Brands/NewBrandsView.vue'),
        meta: {
          title: 'New brands',
        },
      },
      {
        path: '/brands/all/details/:id',
        name: 'Brand details',
        component: () => import('../views/Brands/EditBrandsView.vue'),
        meta: {
          title: 'Brand details',
        },
      },
      {
        path: '/brands/new/details/:id',
        name: 'New brand details',
        component: () => import('../views/Brands/EditBrandsView.vue'),
        meta: {
          title: 'New brand details',
        },
      },
      {
        path: '/brands/all/create',
        name: 'Create new brand',
        component: () => import('../views/Brands/EditBrandsView.vue'),
        meta: {
          title: 'Create new brand',
          create: true,
        },
      },

      /* Products */
      {
        path: '/products/all/:pageId?',
        name: 'All products',
        component: () => import('../views/Products/AllProductsView.vue'),
        meta: {
          title: 'All products',
        },
      },
      {
        path: '/products/all/details/:id',
        name: 'Product details',
        component: () => import('../views/Products/EditAllProductsView.vue'),
        meta: {
          title: 'Product details',
        },
      },
      {
        path: '/products/new/:pageId?',
        name: 'New products',
        component: () => import('../views/Products/NewProductsView.vue'),
        meta: {
          title: 'New products',
        },
      },
      {
        path: '/products/mystery',
        name: 'Mystery products',
        component: () => import('../views/Products/MysteryProductsView.vue'),
        meta: {
          title: 'Mystery products',
        },
      },
      {
        path: '/products/new/details/:id',
        name: 'New product details',
        component: () => import('../views/Products/EditNewProductsView.vue'),
        meta: {
          title: 'New product details',
        },
      },

      /* Ingredients */
      {
        path: '/ingredients/all/:pageId?',
        name: 'All ingredients',
        component: () => import('../views/Ingredients/AllIngredientsView.vue'),
        meta: {
          title: 'All ingredients',
        },
      },
      {
        path: '/ingredients/all/details/:id',
        name: 'Update existing ingredient',
        component: () =>
          import('../views/Ingredients/EditAllIngredientsView.vue'),
        meta: {
          title: 'Update existing ingredient',
          update: true,
        },
      },
      {
        path: '/ingredients/all/create',
        name: 'Add ingredient',
        component: () =>
          import('../views/Ingredients/EditAllIngredientsView.vue'),
        meta: {
          title: 'Add ingredient',
          create: true,
        },
      },
      {
        path: '/ingredients/new/:pageId?',
        name: 'New ingredients',
        component: () => import('../views/Ingredients/NewIngredientsView.vue'),
        meta: {
          title: 'New ingredients',
        },
      },
      {
        path: '/ingredients/new/details/:id',
        name: 'Update new ingredient',
        component: () =>
          import('../views/Ingredients/EditAllIngredientsView.vue'),
        meta: {
          title: 'Update new ingredient',
        },
      },

      /* Ingredients Match */
      {
        path: '/ingredients/match/:pageId?',
        name: 'Ingredients match',
        component: () =>
          import('../views/Ingredients/IngredientsMatchView.vue'),
        meta: {
          title: 'Ingredients match',
        },
      },
      {
        path: '/ingredients/match/create',
        name: 'Add ingredient match',
        component: () =>
          import('../views/Ingredients/EditIngredientsMatchView.vue'),
        meta: {
          title: 'Add ingredient match',
          create: true,
        },
      },
      {
        path: '/ingredients/match/details/:id',
        name: 'Update ingredient match',
        component: () =>
          import('../views/Ingredients/EditIngredientsMatchView.vue'),
        meta: {
          title: 'Update ingredient match',
          update: true,
        },
      },

      /* Push notificationss */
      {
        path: '/notifications/all/:pageId?',
        name: 'All notifications',
        component: () =>
          import('../views/Notifications/AllNotificationsView.vue'),
        meta: {
          title: 'All notifications',
        },
      },
      {
        path: '/notifications/all/details/:id',
        name: 'Update notification',
        component: () =>
          import('../views/Notifications/EditAllNotificationsView.vue'),
        meta: {
          title: 'Update notification',
          update: true,
        },
      },
      {
        path: '/notifications/all/create',
        name: 'Add notification',
        component: () =>
          import('../views/Notifications/EditAllNotificationsView.vue'),
        meta: {
          title: 'Add notification',
          create: true,
        },
      },
      {
        path: '/history/:pageId?',
        name: 'History',
        component: () => import('../views/History/AllHistoryView.vue'),
        meta: {
          title: 'History',
          create: true,
        },
      },
      {
        path: '/hairlab/:pageId?',
        name: 'HairLab',
        component: () => import('../views/HairLab/AllHairLabView.vue'),
        meta: {
          title: 'HairLab',
          create: true,
        },
      },
      /* Mistakes */
      {
        path: '/mistakes/all/:pageId?',
        name: 'All mistakes',
        component: () => import('../views/Mistakes/AllMistakesView.vue'),
        meta: {
          title: 'All mistakes',
        },
      },
      {
        path: '/mistakes/all/details/:id',
        name: 'Update mistake',
        component: () => import('../views/Mistakes/EditAllMistakesView.vue'),
        meta: {
          title: 'Update mistake',
          update: true,
        },
      },

      /* Users */
      {
        path: '/users/all/:pageId?',
        name: 'All users',
        component: () => import('../views/Users/AllUsersView.vue'),
        meta: {
          title: 'All mistakes',
        },
      },
      {
        path: '/users/all/details/:id',
        name: 'Update user',
        component: () => import('../views/Users/EditAllUsersView.vue'),
        meta: {
          title: 'Update user',
          update: true,
        },
      },
      /* Collections */
      {
        path: '/collections/all/:pageId?',
        name: 'All collections',
        component: () => import('../views/Collections/AllCollectionsView.vue'),
        meta: {
          title: 'All collections',
        },
      },
      {
        path: '/collections/all/details/:id',
        name: 'Update collection',
        component: () =>
          import('../views/Collections/EditAllCollectionsView.vue'),
        meta: {
          title: 'Update collection',
          update: true,
        },
      },

      /* Reviewers */
      {
        path: '/reviewers/all/:pageId?',
        name: 'All reviewers',
        component: () => import('../views/Reviewers/AllReviewersView.vue'),
        meta: {
          title: 'All reviewers',
        },
      },
      {
        path: '/reviewers/all/details/:id',
        name: 'Update reviewer',
        component: () => import('../views/Reviewers/EditAllReviewersView.vue'),
        meta: {
          title: 'Update reviewer',
          update: true,
        },
      },

      /* User Reviews */
      {
        path: '/user-reviews/all/:pageId?',
        name: 'All user reviews',
        component: () => import('../views/UserReviews/AllUserReviewsView.vue'),
        meta: {
          title: 'All user reviews',
        },
      },
      {
        path: '/user-reviews/all/details/:id',
        name: 'Edit user review',
        component: () => import('../views/UserReviews/EditUserReviewView.vue'),
        meta: {
          title: 'Edit user review',
          update: true,
        },
      },

      /* Review suggestions */
      {
        path: '/reviews/suggestions/all/:pageId?',
        name: 'All review suggestions',
        component: () =>
          import('../views/ReviewSuggestions/AllReviewSuggestionsView.vue'),
        meta: {
          title: 'All mistakes',
        },
      },
      {
        path: '/reviews/suggestions/details/:id',
        name: 'Update review suggestion',
        component: () =>
          import('../views/ReviewSuggestions/EditAllReviewSuggestionsView.vue'),
        meta: {
          title: 'Update review suggestion',
          update: true,
        },
      },

      /* Images logs */
      {
        path: '/images/all/:pageId?',
        name: 'Images logs',
        component: () => import('../views/ImagesLogs/AllImagesLogsView.vue'),
        meta: {
          title: 'Images logs',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login',
      isNonAuthRequired: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
    meta: {
      title: 'Page not found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: IRoutes, from, next) => {
  document.title = to.meta.title || 'COSMIOS admin panel';

  // check if token exists - try to load normal page
  // otherwise - follow to login page
  if (to.matched.some((record) => record.meta.isAuthRequired)) {
    if (localStorage.getItem('token')) {
      next();
    } else {
      next({ path: '/login' });
    }
  } else if (to.matched.some((record) => record.meta.isNonAuthRequired)) {
    if (localStorage.getItem('token')) {
      next({ path: '/products/all/1' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
